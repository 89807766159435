/* eslint-disable indent */
import { getDaysDuration, tomorrow } from '@shared/utils';

import { InsurancePeriodValues } from '@pulse-smart-components-kit/insurance-period';
import { DEFAULT_CONTRACT_DURATION } from '@shared/queries/application/get-prices/constants';

interface isInsuranceDurationValidArgs {
  insurancePeriod: InsurancePeriodValues | undefined;
  maxDuration: number;
}
export const getInsuranceDuration = ({
  insurancePeriod,
  maxDuration,
}: isInsuranceDurationValidArgs) => {
  const dateBegin = new Date(
    insurancePeriod?.insuranceDates?.start || tomorrow
  );
  let dateEnd = new Date(insurancePeriod?.insuranceDates?.end || tomorrow);

  // Сброс чекбокса полис на год. Дата конца полиса не может быть меньше даты начала полиса.
  if (dateEnd.getTime() < dateBegin.getTime())
    dateEnd = new Date(dateBegin.getTime());

  const shouldUseDefaultDuration =
    !insurancePeriod?.insuranceDates?.start ||
    !insurancePeriod?.insuranceDates?.end ||
    getDaysDuration(dateBegin, dateEnd) > maxDuration;

  const contractDuration = shouldUseDefaultDuration
    ? DEFAULT_CONTRACT_DURATION
    : `P${getDaysDuration(dateBegin, dateEnd)}D`;

  return {
    contractDuration,
    isValid: shouldUseDefaultDuration,
  };
};
