import { useIsFetching } from 'react-query';
import { DOCUMENT_QUERY_KEY, QUERY_KEYS } from '@shared/constants';

export const useGlobalFetching = () => {
  const countFetchings = useIsFetching({
    predicate: (query) => {
      const [key] = query.queryKey;
      return (
        key !== QUERY_KEYS.getPrices &&
        key !== QUERY_KEYS.auth &&
        key !== QUERY_KEYS.confirmAuth &&
        !(key as string).includes(DOCUMENT_QUERY_KEY)
      );
    },
  });

  return Boolean(countFetchings);
};
