import { useState, useEffect, useMemo } from 'react';
import { useStores } from '@shared/hooks';
import type { FooterSportValue } from '@pulse-smart-components-kit/footer-sport';
import { TokenInputOption } from '@pulse-web-ui/token-input';

const defaultFooterValue: FooterSportValue = {
  price: { premiumAndDelta: '', promoCode: '' },
  coverageItems: [],
  insuranceSum: 0,
  dates: { startDate: undefined, endDate: undefined },
};

export const useUpdateFooter = (): FooterSportValue => {
  const {
    MainStore: {
      productStore: {
        price,
        formState: { InsurancePeriod, CoverageSum },
      },
    },
  } = useStores();

  const [value, setValue] = useState<FooterSportValue>(defaultFooterValue);
  const selectedSportsLength = InsurancePeriod?.coverageItems?.length;

  const coverageItems = useMemo(
    () =>
      InsurancePeriod?.coverageItems?.map(
        (item: TokenInputOption) => item.label
      ) || [],
    [InsurancePeriod?.coverageItems]
  );

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      price: {
        ...price,
        ...(!selectedSportsLength ? { premiumAndDelta: '0' } : {}),
      },
      coverageItems,
      insuranceSum: CoverageSum?.coverageSum,
      dates: {
        ...prev.dates,
        startDate: InsurancePeriod?.insuranceDates?.start,
        endDate: InsurancePeriod?.insuranceDates?.end,
      },
    }));
  }, [
    price,
    CoverageSum?.coverageSum,
    coverageItems,
    InsurancePeriod?.insuranceDates?.start,
    InsurancePeriod?.insuranceDates?.end,
    selectedSportsLength,
  ]);

  return value;
};
