import { observer } from 'mobx-react-lite';

import { AuthBox, AuthBoxAnalyticEvent } from '@features/auth-box';
import { useStores } from '@shared/hooks';

import type { FC } from 'react';
import type { WhoIssuesPolicyDataType } from '@features/auth-box';

import { getRegistrationData } from './utils';
import type { AnalyticEventsMap } from '@shared/types';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<AuthBoxAnalyticEvent> = {
  [AuthBoxAnalyticEvent.ON_REGISTRATION_SUCCESS]: {
    name: analyticEvents.authRegistrationSuccess,
    isSingle: true,
  },
  [AuthBoxAnalyticEvent.ON_SIGNUP_SUCCESS]: {
    name: analyticEvents.authSignupSuccess,
    isSingle: true,
  },
  [AuthBoxAnalyticEvent.ON_ENTER_CODE_SUCCESS]: {
    name: analyticEvents.authEnterCodeSuccess,
    isSingle: true,
  },
};

type AuthBoxAdapterProps = {
  updateFormValue: (fieldName: string, value: any) => void;
  setFormValueError: (fieldName: string, error: any) => void;
};

export const AuthBoxAdapter: FC<AuthBoxAdapterProps> = observer(
  ({ updateFormValue, setFormValueError }) => {
    const {
      MainStore: {
        productStore: { formState },
        initProductStore: { regUtm },
        authStore: { promoAgreement },
      },
    } = useStores();

    const registrationData = getRegistrationData(
      formState,
      regUtm,
      promoAgreement
    );

    const handleAnalyticEventSend =
      useHandleSmartComponentsAnalyticEvents<AuthBoxAnalyticEvent>(
        analyticEventsMap
      );

    return (
      <AuthBox
        registrationData={registrationData}
        updateFormValue={updateFormValue}
        setFormValueError={setFormValueError}
        whoIssuesPolicyData={
          formState?.WhoIssuesPolicy as WhoIssuesPolicyDataType
        }
        onAnalyticEventSend={handleAnalyticEventSend}
      />
    );
  }
);

AuthBoxAdapter.displayName = 'AuthBoxAdapter';
