import { analyticEvents } from '@app/web-analytic';
import { useEffect } from 'react';
import { useStores } from '@shared/hooks';

type Dependence = number | boolean | undefined;
type AnalyticEventValues = (typeof analyticEvents)[keyof typeof analyticEvents];

export const useSendSingleAnalyticEvent = (
  dependence: Dependence,
  event: AnalyticEventValues
) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();

  useEffect(() => {
    if (dependence) {
      sendSingleAnalyticEvent(event);
    }
  }, [dependence]);
};
