import { CheckPolicy } from '@entities/import-smart-components/check-policy';
import {
  CheckPolicyOptions,
  CheckPolicyValues,
  CheckPolicyAnalyticEvent,
} from '@pulse-smart-components-kit/check-policy';
import { FallbackSkeleton, withMemo } from '@shared/components';
import { useStores, useHandleDownloadPDF } from '@shared/hooks';
import { declineWord } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo, forwardRef, type Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { schema } from './schema';
import { useGetInsuranceEntities } from './hooks';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import { AnalyticEventsMap } from '@shared/types';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';

const analyticEventsMap: AnalyticEventsMap<CheckPolicyAnalyticEvent> = {
  [CheckPolicyAnalyticEvent.ON_DOCS_ACCEPTED]: {
    name: analyticEvents.docsAccepted,
  },
};

const MemoizedCheckPolicy = withMemo(CheckPolicy, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
]);

export const CheckPolicyAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CheckPolicyValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();

      const {
        MainStore: {
          applicationStore: { paymenStep },
          productStore: {
            formState: { CoverageSum, InsurancePeriod },
            orderData,
          },
          authStore: { disableForm, accessToken },
        },
      } = useStores();

      const coverageSum = CoverageSum?.coverageSum;
      const coverageItems = InsurancePeriod?.coverageItems;
      const isAcceptCheckboxDisabled = disableForm || paymenStep;

      const descriptionItems = useGetInsuranceEntities();

      const productItems = useMemo(
        () =>
          coverageItems?.map(({ label }) => ({
            title: label,
          })),
        [coverageItems]
      );

      const subTitle = useMemo((): string => {
        if (!coverageItems?.length) return '';
        return `${declineWord(coverageItems.length, {
          one: t('COMMON:declensions.type'),
          few: t('COMMON:declensions.type2'),
          many: t('COMMON:declensions.type3'),
        })} ${t('COMMON:labels.sportCase')}`;
      }, [coverageItems?.length]);

      const { handleDownloadClick, documentsStatusList } = useHandleDownloadPDF(
        accessToken || '',
        orderData?.clientDocument
      );

      const options: CheckPolicyOptions = useMemo(
        () => ({
          onDownload: handleDownloadClick,
          documentsList: orderData?.clientDocument,
          policyIncludedConfig: {
            coverageSum,
            aboutProducts: {
              title: t('COMMON:labels.sportTypes'),
            },
            productItems,
            subTitle,
          },
          insuranceEntitiesConfig: {
            items: descriptionItems,
          },
          documentsStatusList,
        }),
        [
          coverageSum,
          subTitle,
          orderData?.clientDocument,
          documentsStatusList,
          productItems,
        ]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CheckPolicyAnalyticEvent>(
          analyticEventsMap
        );

      return (
        <Suspense fallback={<FallbackSkeleton height={280} />}>
          <MemoizedCheckPolicy
            ref={forwardRef}
            value={value}
            onChange={onChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={isAcceptCheckboxDisabled}
            fieldState={fieldState}
            formSchema={schema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

CheckPolicyAdapter.displayName = 'CheckPolicyAdapter';
