import type { InsuredPerson } from '@pulse-smart-components-kit/insured-persons';
import { FieldValues } from 'react-hook-form';

export const extractInsuredPerson = (e: FieldValues) => {
  const insuredPerson: InsuredPerson = {
    firstName: e.name,
    surname: e.surname,
    secondName: e.secondName,
    birthday: e.birthDate,
    id: e.primaryRecordId,
  };

  return insuredPerson;
};
