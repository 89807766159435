// regex для телефонов на +7, 8
export const phoneRegEx =
  /^(\+7|8)?[\s\-]?\(?[4890][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

// проверка на отсутствие пробелов, символов и цифр в первом введенном значении
export const onlyLetters = /^[а-яА-Яa-zA-Z]/;
// проверка на количество допустимых символов в поле
export const maxLength = /^.{1,50}$/;
// проверка на использование кириллицы при вводе фио
export const onlyCyrillic = /^[а-яА-ЯЁё\s'-]+$/;

export const promoEx = /^[A-Za-zА-Яа-яЁё0-9. _]{1,20}$/;

// Регулярное выражение взято из https://pulse-insure.atlassian.net/wiki/spaces/frontapps/pages/176980098?NO_SSR=1#Email
export const emailRegEx =
  /^(?=.{1,64}@)[A-Za-zА-Яа-я0-9_+-]+(\.[A-Za-zА-Яа-я0-9_+-]+)*@[^-][A-Za-zА-Яа-я0-9-]+(\.[A-Za-zА-Яа-я0-9-]+)*(\.[A-Za-zА-Яа-я]{2,})$/u;

export const onlyNumbers = /^\d+$/;

// только числа от 0 до 90
export const ageRegEx = /^(?:[0-9]|[1-8][0-9]|90)$/;
