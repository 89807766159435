/* eslint-disable indent */
import debounce from 'lodash.debounce';
import { Suspense, forwardRef, type Ref, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withMemo } from '@shared/components';
import { useTranslation } from 'react-i18next';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import {
  CoverageSumValues,
  CoverageSumAnalyticEvent,
  CoverageSumOptions,
} from '@pulse-smart-components-kit/coverage-sum';
import { CoverageSum } from '@entities/import-smart-components/coverage-sum-component';
import { getCoverageSumSchema } from './schemas';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { AnalyticEventsMap } from '@shared/types';

const analyticEventsMap: AnalyticEventsMap<CoverageSumAnalyticEvent> = {
  [CoverageSumAnalyticEvent.ON_COVERAGE_SUM_CHANGE]: {
    name: analyticEvents.sumChanged,
  },
  [CoverageSumAnalyticEvent.ON_OPEN_MODAL]: {
    name: analyticEvents.infoSum,
  },
};

const MemoizedCoverageSum = withMemo(CoverageSum, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
  'fieldState',
]);

export const CoverageSumAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<CoverageSumValues, CoverageSumOptions>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const { t } = useTranslation();
      const {
        MainStore: {
          initProductStore: {
            initState: { sublimits, risks },
          },
          productStore: { hasPricesCalcErr },
          authStore: { disableForm },
        },
      } = useStores();

      const handleChangeCoverageSumDebounced = debounce(onChange, 200);

      useEffect(() => handleChangeCoverageSumDebounced.cancel, []);

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<CoverageSumAnalyticEvent>(
          analyticEventsMap
        );

      const handleAnalyticEventSendDebounced = debounce(
        handleAnalyticEventSend,
        200
      );

      const min = useMemo(
        () =>
          sublimits?.totalMinLimit ? parseFloat(sublimits.totalMinLimit) : 0,
        [sublimits?.totalMinLimit]
      );

      const max = useMemo(
        () =>
          sublimits?.totalMaxLimit ? parseFloat(sublimits.totalMaxLimit) : 0,
        [sublimits?.totalMaxLimit]
      );

      const formSchema = getCoverageSumSchema(min, max);

      const formattedRisksNames = {
        Accident_7: t('SMART:CoverageSum.declensions.Accident_7'),
        Accident_8: t('SMART:CoverageSum.declensions.Accident_8'),
      };

      const activeRisks = useMemo(
        () =>
          risks
            ?.filter((risk) => risk.active)
            .map(({ name, description, code }) => ({
              name:
                formattedRisksNames[code as keyof typeof formattedRisksNames] ||
                name,
              description,
              code,
            })),
        [risks]
      );

      const options = useMemo(
        () => ({
          min,
          max,
          activeRisks,
          step: sublimits?.totalStep ? parseFloat(sublimits.totalStep) : 0,
        }),
        [sublimits?.totalStep, min, max, activeRisks]
      );

      const coverageSumValue = useMemo(() => {
        if (
          !!value &&
          !value?.coverageSum &&
          !!sublimits?.totalDefaultInsuranceSum
        ) {
          return {
            ...value,
            coverageSum: parseFloat(sublimits.totalDefaultInsuranceSum),
          };
        }

        return value;
      }, [value, sublimits?.totalDefaultInsuranceSum]);

      const fieldStateWithErrors = useMemo(() => {
        return {
          ...fieldState,
          ...(hasPricesCalcErr
            ? {
                error: {
                  ...(fieldState?.error || {}),
                  coverageSum: {
                    message: t('SMART:CoverageSum.hints.overPriceError'),
                    type: 'customError',
                  },
                },
              }
            : {}),
        };
      }, [hasPricesCalcErr, fieldState]);

      if (!coverageSumValue?.coverageSum) return null;

      return (
        <Suspense>
          <MemoizedCoverageSum
            ref={forwardRef}
            value={coverageSumValue}
            onChange={handleChangeCoverageSumDebounced}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldStateWithErrors}
            formSchema={formSchema}
            onAnalyticEventSend={handleAnalyticEventSendDebounced}
          />
        </Suspense>
      );
    }
  )
);

CoverageSumAdapter.displayName = 'CoverageSumAdapter';
