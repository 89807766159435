import { useStores } from '@shared/hooks';
import { ProfileLockedFields } from '@shared/types';
import { InsuredPersonsValues } from '@pulse-smart-components-kit/insured-persons';
import { useCallback } from 'react';

export const useUpdatePolicyholder = () => {
  const {
    MainStore: {
      authStore: { disableForm, disableProfileState },
      productStore: {
        formState: { WhoIssuesPolicy },
      },
      applicationStore: { updateFormValue },
    },
  } = useStores();

  const getDisableState = useCallback(
    (name: keyof ProfileLockedFields) => {
      if (disableForm) return true;
      if (disableProfileState?.[name] === false) return false;
      if (WhoIssuesPolicy?.hasAuthorized) return true;

      return false;
    },
    [disableForm, disableProfileState, WhoIssuesPolicy?.hasAuthorized]
  );

  const updatePolicyholder = useCallback(
    (
      data: InsuredPersonsValues,
      isDeleteMyself: boolean,
      setIsDeleteMyself: (value: boolean) => void
    ) => {
      if (WhoIssuesPolicy?.forMe) {
        const updatedIssuesPolicyLocal = { ...WhoIssuesPolicy };

        if (
          !getDisableState('secondName') &&
          WhoIssuesPolicy.secondName !== data.insuredPersons[0].secondName
        ) {
          updatedIssuesPolicyLocal.secondName =
            data.insuredPersons[0].secondName;
        }
        if (
          !getDisableState('surname') &&
          WhoIssuesPolicy.surname !== data.insuredPersons[0].surname
        ) {
          updatedIssuesPolicyLocal.surname = data.insuredPersons[0].surname;
        }
        if (
          !getDisableState('name') &&
          WhoIssuesPolicy.name !== data.insuredPersons[0].firstName
        ) {
          updatedIssuesPolicyLocal.name = data.insuredPersons[0].firstName;
        }
        if (
          !getDisableState('birthDate') &&
          WhoIssuesPolicy.birthDate?.getTime() !==
            data.insuredPersons[0].birthday?.getTime()
        ) {
          updatedIssuesPolicyLocal.birthDate = data.insuredPersons[0].birthday;
        }
        updateFormValue('WhoIssuesPolicy', {
          ...updatedIssuesPolicyLocal,
          ...(isDeleteMyself ? { forMe: false } : {}),
        });

        if (isDeleteMyself) {
          setIsDeleteMyself(false);
        }
      }
    },
    [WhoIssuesPolicy, getDisableState]
  );

  return updatePolicyholder;
};
