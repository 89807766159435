import {
  Suspense,
  forwardRef,
  type Ref,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '@shared/hooks';
import type { SmartComponentAdapterProps } from '@pulse-smart-components-kit/common';
import { InsuredPersons } from '@entities/import-smart-components/insured-persons-component';
import {
  InsuredPersonsOptions,
  InsuredPersonsValues,
  InsuredPersonsAnalyticEvent,
} from '@pulse-smart-components-kit/insured-persons';
import { getInsuredPersonsSchema } from './schemas';
import {
  analyticEvents,
  useHandleSmartComponentsAnalyticEvents,
} from '@app/web-analytic';
import { AnalyticEventsMap } from '@shared/types';
import { withMemo } from '@shared/components';
import {
  useSynchronizationInsuredPersons,
  useUpdatePolicyholder,
} from './hooks';

const analyticEventsMap: AnalyticEventsMap<InsuredPersonsAnalyticEvent> = {
  [InsuredPersonsAnalyticEvent.ON_DATA_ENTERED]: {
    name: analyticEvents.sportsmanDataEntered,
  },
  [InsuredPersonsAnalyticEvent.ON_PERSON_ADD]: {
    name: analyticEvents.sportsmanAdded,
  },
  [InsuredPersonsAnalyticEvent.ON_PERSON_REMOVE]: {
    name: analyticEvents.sportsmanDeleted,
  },
};

const MemoizedInsuredPersons = withMemo(InsuredPersons, [
  'disabled',
  'isSubmitting',
  'value',
  'options',
  'onChange',
]);

export const InsuredPersonsAdapter = observer(
  forwardRef(
    (
      {
        value,
        onChange,
        isSubmitting,
        fieldState,
      }: SmartComponentAdapterProps<InsuredPersonsValues>,
      forwardRef: Ref<HTMLDivElement>
    ) => {
      const {
        MainStore: {
          initProductStore: { initState },
          authStore: { disableForm, profile },
          productStore: {
            ageRange,
            formState: { WhoIssuesPolicy, NumberOfInsuredPersons },
          },
          applicationStore: { updateFormValue },
        },
      } = useStores();
      const updatePolicyholder = useUpdatePolicyholder();
      const [isDeleteMyself, setIsDeleteMyself] = useState(false);

      useSynchronizationInsuredPersons(value);
      const formSchema = getInsuredPersonsSchema();

      const handleDeleteMyself = useCallback(() => {
        setIsDeleteMyself(true);
      }, [setIsDeleteMyself]);

      const choosedPersonNumber =
        NumberOfInsuredPersons?.numberOfInsuredPersons ?? 1;

      const options: InsuredPersonsOptions = useMemo(
        () => ({
          isMe: WhoIssuesPolicy?.forMe,
          choosedPersonNumber,
          maxPersonNumber: initState?.maxObjectsNumber ?? 1,
          ageRange,
          onDeleteMyself: handleDeleteMyself,
          lockedFields: profile?.lockedFields,
        }),
        [
          WhoIssuesPolicy?.forMe,
          choosedPersonNumber,
          ageRange,
          handleDeleteMyself,
          initState?.maxObjectsNumber,
          profile?.lockedFields,
        ]
      );

      const onHandleChange = useCallback(
        (data: InsuredPersonsValues) => {
          updateFormValue('NumberOfInsuredPersons', {
            isValid: true,
            numberOfInsuredPersons: data.insuredPersons.length,
          });
          onChange(data);
          updatePolicyholder(data, isDeleteMyself, setIsDeleteMyself);
        },
        [updatePolicyholder, isDeleteMyself]
      );

      const handleAnalyticEventSend =
        useHandleSmartComponentsAnalyticEvents<InsuredPersonsAnalyticEvent>(
          analyticEventsMap
        );

      if (!WhoIssuesPolicy?.hasAuthorized) return null;

      return (
        <Suspense>
          <MemoizedInsuredPersons
            ref={forwardRef}
            value={!value?.insuredPersons ? { insuredPersons: [] } : value}
            onChange={onHandleChange}
            isSubmitting={isSubmitting}
            options={options}
            disabled={disableForm}
            fieldState={fieldState}
            formSchema={formSchema}
            onAnalyticEventSend={handleAnalyticEventSend}
          />
        </Suspense>
      );
    }
  )
);

InsuredPersonsAdapter.displayName = 'InsuredPersonsAdapter';
