import { array, boolean, date, object } from 'yup';
import i18n from '@app/i18n/config';

export const getInsurancePeriodSchema = (
  startDate: string,
  maxDuration: number,
  maxSportsCount: number
) =>
  object().shape({
    coverageItems: array()
      .test(
        'minCount',
        i18n.t('SMART:InsurancePeriod.errors.sportsRequired'),
        (value) => !!value?.length
      )
      .test(
        'maxCount',
        i18n.t('SMART:InsurancePeriod.errors.maxSportsCount', {
          maxSportsCount,
        }),
        (value) =>
          !!maxSportsCount && !!value?.length && value?.length <= maxSportsCount
      ),
    yearPolicy: boolean(),
    insuranceDates: object({
      start: date().required(
        i18n.t('SMART:InsurancePeriod.errors.startDateRequired')
      ),
      end: date()
        .required(i18n.t('SMART:InsurancePeriod.errors.endDateRequired'))
        .test(
          'maxDuration',
          i18n.t('SMART:InsurancePeriod.errors.dateGreaterMaxDuration', {
            maxDuration,
          }),
          (value, ctx) => {
            const dateEnd = new Date(value || '');
            const maxDurationDate = new Date(ctx?.parent?.start || startDate);
            maxDurationDate.setDate(maxDurationDate.getDate() + maxDuration);

            return dateEnd < maxDurationDate;
          }
        ),
    }),
    isValid: boolean(),
  });
