/* eslint-disable */
import { media, styled } from '@pulse-web-ui/theme';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  row-gap: 16px;
  border-radius: 24px;
  padding: 20px 16px;

  ${media.desktop} {
    row-gap: 24px;
    padding: 24px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px 16px;

  ${media.desktop} {
    flex-direction: row;
  }
`;

export const RowLast = styled.div`
  ${media.desktop} {
    max-width: 343px;
  }
`;

export const RowSection = styled.div`
  display: flex;
  flex-basis: 50%;
  & > * {
    width: 100%;
  }
`;

export const RowSectionBasic = styled.div``;

export const RowSwitcher = styled.div`
  display: flex;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${media.desktop} {
    gap: 16px;
  }
`;
