import { commonNS, commonNamespaces } from './common';
import { insurancePeriodNS } from './insurance-period';
import { numberOfInsuredPersonsNS } from './number-of-insured-persons';
import { coverageSumNS } from './coverage-sum';
import { insuredPersonsNS } from './insured-person';

const smartNamespaces = 'SMART';

export const resources = {
  ru: {
    ...commonNS,
    [smartNamespaces]: {
      ...insurancePeriodNS,
      ...numberOfInsuredPersonsNS,
      ...coverageSumNS,
      ...insuredPersonsNS,
    },
  },
};

export const namespaces = [...commonNamespaces, smartNamespaces];
