import { boolean, object, number } from 'yup';

export const getNumberOfInsuredPersonsSchema = (
  minCount: number,
  maxCount: number
) =>
  object().shape({
    numberOfInsuredPersons: number().required().min(minCount).max(maxCount),
    isValid: boolean(),
  });
