export const analyticEvents = {
  webStart: 'Web_Start', // При загрузке страницы
  start: 'Sport_Start', // При взаимодействии с ЛЮБЫМ элементом этого экрана один раз отправляем
  sportSelected: 'Sport_Sport_Selected', // Выбран хотя бы один вид спорта
  datesSelected: 'Sport_Dates_Selected', // Выбраны даты
  sumChanged: 'Sport_Sum_Changed', // Изменена сумма
  infoSum: 'Sport_Info_Summ', // Открытие попапа после клика на “как выбрать сумму”
  promoSelected: 'Sport_Promo_Selected', // Проставлена галочка “есть промокод” и введен успешно примененный промокод
  selectedSummary: 'Sport_Sport_Selected_Summary', // Клик на выбранные виды спорта (все экраны)
  authSignupStart: 'Sport_Auth_Signup_Start', // Начало авторизации зарегистрированного пользователя
  authRegistrationStart: 'Sport_Auth_Registration_Start', // Начало регистрации нового пользователя
  authSignupSuccess: 'Sport_Auth_Signup_Success', // Выбран чекбокс “я клиент Пульса” и успешно пройдена авторизация существующего клиента
  authEnterCodeSuccess: 'Sport_Auth_EnterCode_Success', // Введен код и валидация пройдена
  authRegistrationSuccess: 'Sport_Auth_Registration_Success', // Успешно зарегистрирован новый пользователь
  ownerDataEntered: 'Sport_Owner_Data_Entered', // Введены ФИО + дата рождения и убран фокус с поля
  ownerPhoneEntered: 'Sport_Owner_Phone_Entered', // Введен номер телефона и убран фокус с поля
  ownerMailEntered: 'Sport_Owner_Mail_Entered', // Введен e-mail и убран фокус с поля
  sportsmanDataEntered: 'Sport_Sportsman_Data_Entered', // Введено ФИО и дата рождения каждого спортсмена и убран фокус с поля
  sportsmanAdded: 'Sport_Sportsman_Added', // Добавлен спортсмен
  sportsmanDeleted: 'Sport_Sportsman_Deleted', // Удален спортсмен
  toStepSportData: 'Sport_To_Step_SportData', // Открытие экрана "Расчет"
  toStepData: 'Sport_To_Step_Data', // Открытие экрана "Оформление"
  toStepDocs: 'Sport_To_Step_Docs', // Открытие экрана с проверкой введенных данных
  toStepPay: 'Sport_To_Step_Pay', // Клик на “перейти к оплате”
  docsAccepted: 'Sport_Docs_Accepted', // Проставлена галочка “Принимаю условия страхового полиса, правил страхования, ключевого информационного документа
  paymentSuccess: 'Sport_Payment_Success', // Оплата отклонена
  paymentDecline: 'Sport_Payment_Decline', // Успешная оплата
  downloadApp: 'Sport_Download_App', // Клик на “установить приложение” после успешной оплаты полиса
} as const;
