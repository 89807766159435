import { useEffect } from 'react';
import type {
  InsuredPerson,
  InsuredPersonsValues,
} from '@pulse-smart-components-kit/insured-persons';
import { useStores } from '@shared/hooks';
import { extractInsuredPerson } from '@entities/adapters/number-of-insured-persons-adapter';

export const useSynchronizationInsuredPersons = (
  value: InsuredPersonsValues | undefined
) => {
  const {
    MainStore: {
      productStore: {
        formState: { WhoIssuesPolicy, InsuredPersons },
      },
      applicationStore: { updateFormValue },
    },
  } = useStores();

  const isForMe = WhoIssuesPolicy?.forMe;
  const isDifferentFirstPerson =
    value?.insuredPersons[0]?.id !== InsuredPersons?.insuredPersons[0]?.id;
  const isEqualArrayLength =
    value?.insuredPersons.length === InsuredPersons?.insuredPersons.length;
  const isNeedSynchronization =
    isForMe && isDifferentFirstPerson && isEqualArrayLength;

  useEffect(() => {
    if (isNeedSynchronization) {
      const persons: InsuredPerson[] = InsuredPersons?.insuredPersons || [];
      persons[0] = extractInsuredPerson(WhoIssuesPolicy);
      updateFormValue('InsuredPersons', {
        ...InsuredPersons,
        insuredPersons: [...persons],
      });
    }
  }, [isNeedSynchronization]);
};
