export const scrollToIframe = () => {
  window.parent.postMessage({ action: 'scrollToIframe' }, '*');
};

export const scrollToElement = (elementOffsetTop: number) => {
  window.parent.postMessage(
    { action: 'scrollToElement', elementOffsetTop },
    '*'
  );
};
