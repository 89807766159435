import { memo, useEffect, useRef } from 'react';
import type { FC } from 'react';
import { Form, StyledIframe, B2pWrapper } from './b2p.style';
import type { SmartComponentProps } from 'smart-components/shared/types';
import type { B2PValues } from './b2p.type';
import { scrollToElement } from '@shared/utils';

export enum B2PAnalyticEvent {
  IS_STEP_PAY,
}

const B2P: FC<SmartComponentProps<B2PValues, null, B2PAnalyticEvent>> = memo(
  ({ value, onAnalyticEventSend }) => {
    const ref = useRef<null | HTMLDivElement>(null);
    const sector = `sector=${value?.initOrder?.sector}`;
    const id = `id=${value?.initOrder?.id}`;
    const signature = `signature=${value?.initOrder?.signature}`;
    const url = `url=${value?.initOrder?.url}`;
    const failUrl = `failUrl=${value?.initOrder?.failUrl}`;
    const get_token = 1;

    const propsString = [sector, id, signature, url, failUrl, get_token].join(
      '&'
    );

    const route = `Purchase?${propsString}`;

    useEffect(() => {
      if (value?.showInitOrder) {
        onAnalyticEventSend &&
          onAnalyticEventSend(B2PAnalyticEvent.IS_STEP_PAY);

        scrollToElement(Number(ref?.current?.offsetTop));
      }
    }, [value?.showInitOrder]);

    if (value?.showInitOrder) {
      return (
        <B2pWrapper ref={ref} data-test="B2P-block">
          <Form>
            <StyledIframe
              src={`${window.envUrls.BEST2PAY_API_URL}/${route}`}
              allow="payment"
              sandbox="allow-top-navigation allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox"
            />
          </Form>
        </B2pWrapper>
      );
    }

    return null;
  }
);

export default B2P;
