import { v4 as uuid } from 'uuid';
import type { InsuredPerson } from '@pulse-smart-components-kit/insured-persons';

export const createOnePerson = () => {
  return {
    firstName: '',
    surname: '',
    secondName: '',
    birthday: null,
    id: uuid(),
  } as InsuredPerson;
};
