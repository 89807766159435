import { useStores } from '@shared/hooks';
import { ProfileLockedFields } from '@shared/types';
import { useCallback } from 'react';
import type { WhoIssuesPolicyValues } from 'smart-components';
import type { InsuredPerson } from '@pulse-smart-components-kit/insured-persons';

export const useUpdateInsuredPerson = () => {
  const {
    MainStore: {
      authStore: { disableForm, disableProfileState },
      productStore: {
        formState: { InsuredPersons, WhoIssuesPolicy },
      },
      applicationStore: { updateFormValue },
    },
  } = useStores();
  //TODO: вынести, переиспользовать, дубль /insured-persons-adapter/hooks/useUpdatePolicyholder.ts
  const getDisableState = useCallback(
    (name: keyof ProfileLockedFields) => {
      if (disableForm) return true;
      if (disableProfileState?.[name] === false) return false;
      if (WhoIssuesPolicy?.hasAuthorized) return true;

      return false;
    },
    [disableForm, disableProfileState, WhoIssuesPolicy?.hasAuthorized]
  );
  const updatePerson = useCallback(
    (data: WhoIssuesPolicyValues) => {
      if (data?.forMe && InsuredPersons?.insuredPersons[0]) {
        const persons = InsuredPersons?.insuredPersons || [];
        const updatedPersonLocal: InsuredPerson = {
          ...InsuredPersons.insuredPersons[0],
        };
        //TODO: вынести, переиспользовать, возможно дубль /insured-persons-adapter/hooks/useUpdatePolicyholder.ts
        if (
          !getDisableState('secondName') &&
          data.secondName !== updatedPersonLocal.secondName
        ) {
          updatedPersonLocal.secondName = data.secondName || '';
        }

        if (
          !getDisableState('surname') &&
          data.surname !== updatedPersonLocal.surname
        ) {
          updatedPersonLocal.surname = data.surname || '';
        }

        if (
          !getDisableState('name') &&
          data.name !== updatedPersonLocal.firstName
        ) {
          updatedPersonLocal.firstName = data.name || '';
        }

        if (
          !getDisableState('birthDate') &&
          data.birthDate.getTime() !== updatedPersonLocal.birthday?.getTime()
        ) {
          updatedPersonLocal.birthday = data.birthDate;
        }

        const hasDifferentValues = Object.keys(updatedPersonLocal).some(
          (key) =>
            updatedPersonLocal[key as keyof InsuredPerson] !==
            persons[0][key as keyof InsuredPerson]
        );

        if (hasDifferentValues) {
          updateFormValue('InsuredPersons', {
            ...InsuredPersons,
            insuredPersons: [updatedPersonLocal, ...persons.slice(1)],
          });
        }
      }
    },
    [InsuredPersons, getDisableState]
  );

  return updatePerson;
};
