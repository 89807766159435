import type { FC } from 'react';
import type { SmartComponentAdapterProps } from 'smart-components';

import {
  FooterAdapter,
  HeaderAdapter,
  SubmitAdapter,
  WhoIssuesPolicyAdapter,
  B2PAdapter,
  InsurancePeriodAdapter,
  NumberOfInsuredPersonsAdapter,
  CoverageSumAdapter,
  CheckPolicyAdapter,
  InsuredPersonsAdapter,
} from '@entities/adapters';

type TypeImportSmartComponents = {
  [key: string]: FC<SmartComponentAdapterProps<any, any>>;
};

export const importSmartComponents: TypeImportSmartComponents = {
  WhoIssuesPolicy: WhoIssuesPolicyAdapter,
  B2P: B2PAdapter,
  InsurancePeriod: InsurancePeriodAdapter,
  NumberOfInsuredPersons: NumberOfInsuredPersonsAdapter,
  CoverageSum: CoverageSumAdapter,
  CheckPolicy: CheckPolicyAdapter,
  InsuredPersons: InsuredPersonsAdapter,
};

type TypeImportCommonComponents = {
  [key: string]: FC<any>;
};

export const importCommonComponents: TypeImportCommonComponents = {
  Header: HeaderAdapter,
  Footer: FooterAdapter,
  Submit: SubmitAdapter,
};
