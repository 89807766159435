import { InsurancePeriodOptions } from '@pulse-smart-components-kit/insurance-period';
import { Category } from '@shared/types/sports.types';

const POPULAR_DISPLAY_CATEGORY = 'Популярное';

export const getSports = (categories: Category[]) =>
  categories.reduce(
    (
      acc: Exclude<InsurancePeriodOptions['coverageItems'], undefined>,
      { sports, displayCategory }
    ) => [
      ...acc,
      ...sports.map(({ code, name }) => ({
        code,
        name,
        ...(displayCategory === POPULAR_DISPLAY_CATEGORY
          ? { displayCategory }
          : {}),
      })),
    ],
    []
  );
