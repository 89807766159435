import { InsuredPerson } from '@pulse-smart-components-kit/insured-persons';
import { ObjectSchema, array, boolean, date, object, string } from 'yup';
import i18n from '@app/i18n/config';
import { maxLength, onlyCyrillic, onlyLetters } from '@shared/constants';

const person: ObjectSchema<InsuredPerson> = object({
  firstName: string()
    .required(i18n.t('COMMON:errors.notFilled'))
    .matches(maxLength, i18n.t('COMMON:errors.maxLength'))
    .matches(onlyLetters, i18n.t('COMMON:errors.nameStartWithLetter'))
    .matches(onlyCyrillic, i18n.t('COMMON:errors.useCyrillic')),
  surname: string()
    .required(i18n.t('COMMON:errors.notFilled'))
    .matches(maxLength, i18n.t('COMMON:errors.maxLength'))
    .matches(onlyLetters, i18n.t('COMMON:errors.lastnameStartWithLetter'))
    .matches(onlyCyrillic, i18n.t('COMMON:errors.useCyrillic')),
  secondName: string()
    .optional()
    .when({
      is: (value: string | any[]) => value?.length,
      then: () =>
        string()
          .matches(maxLength, i18n.t('COMMON:errors.maxLength'))
          .matches(
            onlyLetters,
            i18n.t('COMMON:errors.middleNameStartWithLetter')
          )
          .matches(onlyCyrillic, i18n.t('COMMON:errors.useCyrillic')),
    }),
  birthday: date().nullable().required(i18n.t('COMMON:errors.notFilled')),
  id: string().required(),
});

export const getInsuredPersonsSchema = () =>
  object().shape({
    insuredPersons: array(person).required(),
    isValid: boolean(),
  });
