import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useStores } from '@shared/hooks';

import { InsuredList } from '../check-policy.styles';

export const useGetInsuranceEntities = () => {
  const { t } = useTranslation();

  const {
    MainStore: {
      productStore: {
        formState: { WhoIssuesPolicy, InsuredPersons },
      },
    },
  } = useStores();

  const insurantInfo = useMemo(
    () => ({
      title: t('COMMON:labels.insurant'),
      description: (
        <>
          {WhoIssuesPolicy?.surname} {WhoIssuesPolicy?.name}{' '}
          {WhoIssuesPolicy?.secondName} <br />
          {WhoIssuesPolicy?.email}
        </>
      ),
      key: 1,
    }),
    [WhoIssuesPolicy]
  );

  const insuredInfo = useMemo(
    () => ({
      title: t('COMMON:labels.insured'),
      description: (
        <InsuredList>
          {InsuredPersons?.insuredPersons.map(
            ({ firstName, secondName, surname, birthday }) => (
              <span key={`${firstName}${secondName || ''}${surname}`}>
                {surname} {firstName} {secondName || ''}
                <br />
                {birthday && format(birthday, 'dd.MM.yyyy')}
              </span>
            )
          )}
        </InsuredList>
      ),
      key: 2,
    }),
    [InsuredPersons?.insuredPersons]
  );

  return useMemo(
    () => [insurantInfo, insuredInfo],
    [insurantInfo, insuredInfo]
  );
};
