import { InsurancePeriodOptions } from '@pulse-smart-components-kit/insurance-period';
import type { RisksResponse } from '@shared/queries';
import type {
  GetCountryResponse,
  GetSublimitsResponse,
  InsuranceLimitStart,
  SportInsuranceProduct,
} from '@shared/types';
import { GetSportsResponse } from '@shared/types/sports.types';
import { getSports } from '@shared/utils';
import { action, makeAutoObservable, observable } from 'mobx';

const initInsuranceStartLimit: InsuranceLimitStart = {
  endDate: new Date().toISOString(),
  startDate: new Date().toISOString(),
  minDuration: 0,
  maxDuration: 0,
};

interface SportsData {
  sports?: InsurancePeriodOptions['coverageItems'];
  maxSportsCount?: number;
}

interface SublimitsData {
  sublimits?: GetSublimitsResponse;
}

export type InitStateType = Partial<SportInsuranceProduct> &
  Partial<GetCountryResponse> &
  Partial<RisksResponse> &
  SportsData &
  SublimitsData;

export class InitProductStore {
  initState: InitStateType = {}; // Начальные свойства объекта при инициализации приложения
  insuranceLimitStart: InsuranceLimitStart = initInsuranceStartLimit;
  regUtm?: Record<string, any> = undefined; // Метки передаваемые в урл (аналитика)
  initOrder = undefined; // Данные после успешного ответа от init-order
  formBuilderIsMounted = false; // Признак монтирования форм билдера, чтобы задавать начальные данные формы после монтирования
  isInitSessionRecovery = true; // Флаг, указывающий на необходимость запуска восстановления сессии

  constructor() {
    makeAutoObservable(this, {
      initState: observable,
      setInitState: action,
      setSportsSublimits: action,
      insuranceLimitStart: observable,
      setInsuranceLimitStart: action,
      initOrder: observable,
      setInitOrder: action,
      regUtm: observable,
      setRegUtm: action,
      formBuilderIsMounted: observable,
      setFormBuilderIsMounted: action,
    });
  }

  setInitState = (e: RisksResponse | SportInsuranceProduct) => {
    this.initState = {
      ...this.initState,
      ...e,
    };
  };

  setSportsSublimits = (e: GetSportsResponse, v: GetSublimitsResponse) => {
    this.initState = {
      ...this.initState,
      sports: getSports(e.categories),
      maxSportsCount: e.maxCount,
      sublimits: v,
    };
  };

  setInsuranceLimitStart = (e: InsuranceLimitStart) => {
    this.insuranceLimitStart = e;
  };

  setRegUtm = (e: Record<string, any>) => {
    this.regUtm = e;
  };

  setInitOrder = (e: any) => {
    this.initOrder = e;
  };

  setFormBuilderIsMounted = () => {
    this.formBuilderIsMounted = true;
  };

  setIsInitSessionRecovery = (e: boolean) => {
    this.isInitSessionRecovery = e;
  };
}
