import { UpdateProfileResponse } from '@shared/queries';
import { WhoIssuesPolicyValues } from './who-issues-policy.type';
import { EMPTY_SURNAME } from '@shared/constants';

interface GetAuthProfileArgs {
  profile: UpdateProfileResponse['profile'];
  forMe?: boolean;
  hasAuthorized?: boolean;
}
export const getAuthProfileByResponse = ({
  profile,
  forMe,
  hasAuthorized,
}: GetAuthProfileArgs): Partial<WhoIssuesPolicyValues> => ({
  name: profile.firstName,
  surname: profile.lastName === EMPTY_SURNAME ? '' : profile.lastName,
  secondName: profile.middleName,
  phone: profile.phone?.slice(2),
  birthDate: profile.birthDate ? new Date(profile.birthDate) : undefined,
  email: profile.email || '',
  forMe: forMe ?? profileDataIsFulfilled(profile),
  hasAuthorized,
  isValid: !!profile.email && !!profile.birthDate,
  primaryRecordId: profile.primaryRecordId,
});

// Оформляю полис на себя включен, если у профайла заполнены все поля для заполнения путешественника.
const profileDataIsFulfilled = (
  profile: UpdateProfileResponse['profile']
): boolean => !!profile.firstName && !!profile.lastName && !!profile.birthDate;
